import request from "../utils/request";
// 获取手机号列表
export const mobileList = (query) => {
  return request({
    url: "/service/mobile/mobileList",
    method: "POST",
    data: query,
  });
};
// 设置权限
export const setAuth = (query) => {
  return request({
    url: "/service/mobile/setAuth",
    method: "POST",
    data: query,
  });
};
// 修改备注
export const changeRemark = (query) => {
  return request({
    url: "/service/mobile/changeRemark",
    method: "POST",
    data: query,
  });
};
// 设置拨打状态
export const setCall = (query) => {
  return request({
    url: "/service/mobile/setCall",
    method: "POST",
    data: query,
  });
};