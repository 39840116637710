<template>
  <div>
    <pageTitle :title="title"></pageTitle>
    <div class="head bb p30 flex fw_w">
      <div>
        <div>关键词</div>
        <div class="w300">
          <el-input clearable
                    size="large"
                    v-model="search"
                    placeholder="请输入昵称/手机号/真实姓名"></el-input>
        </div>
      </div>
      <div>
        <div>时间</div>
        <div>
          <el-date-picker format="YYYY-MM-DD"
                          value-format="x"
                          size="large"
                          v-model="time_range"
                          type="daterange"
                          align="right"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
      <div>
        <div>状态</div>
        <div>
          <el-select filterable
                     size="large"
                     v-model="status"
                     placeholder="请选择">
            <el-option v-for="item in statusList"
                       :key="item.id"
                       :label="item.label"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <el-button @click="retrieval"
                   type="primary">搜 索</el-button>
      </div>
    </div>
    <div class="bb pl30 pr30">
      <div v-loading="userListLoading">
        <div class="mb20">
          <el-table :data="tableData"
                    height="60vh"
                    style="width: 100%">
            <el-table-column show-overflow-tooltip
                             label="昵称"
                             width="120">
              <template #default="scope">
                {{scope.row.user.nickname}}
              </template>
            </el-table-column>
            <el-table-column label="手机号"
                             width="120">
              <template #default="scope">
                {{scope.row.user.mobile}}
              </template>
            </el-table-column>
            <el-table-column label="时间"
                             width="170"
                             prop="createtime">
            </el-table-column>
            <el-table-column label="奖品"
                             width="170"
                             prop="gift_name">
            </el-table-column>
            <el-table-column label="状态"
                             width="170">
              <template #default="scope">
                <el-tag class="ml-2"
                        type="info"
                        v-if="scope.row.status==-1">未中奖</el-tag>
                <el-tag class="ml-2"
                        type="danger"
                        v-if="scope.row.status==1">未发放</el-tag>
                <el-tag class="ml-2"
                        type="success"
                        v-if="scope.row.status==2">已发放</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             width="300"
                             align="center">
              <template #default="scope">
                <el-button v-if="scope.row.status==1"
                           type="primary"
                           size="small"
                           @click="handleAward(scope.$index, scope.row)">发奖</el-button>
                <span v-else>-</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <el-pagination @current-change="handleCurrentChange"
                         :current-page="page"
                         :page-size="15"
                         layout="total, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as Service from "../api/myClient";
import * as Service from "../api/winningList";
import * as ServiceIndex from "../api/index";
import pageTitle from "../common/pageTitle.vue";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      title: "中奖列表",
      search: "", // 关键词
      time_range: [], // 时间范围
      status: 0, // -1未中奖0全部1未发放2已发放
      statusList: [
        {
          id: 0,
          label: "全部",
        },
        {
          id: -1,
          label: "未中奖",
        },
        {
          id: 1,
          label: "未发放",
        },
        {
          id: 2,
          label: "已发放",
        },
      ], // 查询的筛选条件等级
      userListLoading: false, // 会员列表加载状态
      tableData: [],
      page: 1,
      total: 0,
      is_service_admin: localStorage.getItem("is_service_admin"), // 0非管理员1管理员
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getList(1);
  },
  methods: {
    onmessage(e) {},
    // 搜索操作
    retrieval() {
      this.getList(1);
    },
    // 获取会员抽奖记录
    async getList(page) {
      this.userListLoading = true;
      var time_range0 = this.time_range ? [...this.time_range] : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }
      const res = await Service.getList({
        page,
        search: this.search,
        time_range: time_range0,
        status: this.status,
      });
      this.page = page;
      this.total = res.data.total;
      this.tableData = res.data.data;
      this.userListLoading = false;
    },
    // 发奖
    async handleAward(index, row) {
      const res = await Service.awardPrizes({
        id: row.id,
      });
      this.$message.success(res.msg)
      this.getList(this.page);
    },
    handleCurrentChange(val) {
      this.getList(val);
      // console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style scoped>
.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.w202 /deep/.el-input__inner {
  width: 202px !important;
}

.w202 .date /deep/.el-input__suffix {
  right: 33px !important;
}

.avatar {
  width: 45px;
  height: 45px;
}
</style>