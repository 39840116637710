<template>
  <div>
    <pageTitle :title="title"></pageTitle>
    <div class="head bb p30 flex fw_w">
      <div>
        <div>关键词</div>
        <div class="w300">
          <el-input clearable
                    size="large"
                    v-model="search"
                    placeholder="请输入手机号"></el-input>
        </div>
      </div>
      <div>
        <div>封禁类型</div>
        <div>
          <el-select size="large"
                     v-model="type"
                     placeholder="请选择">
            <el-option v-for="item in banType"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <!-- <div>
        <div>部门</div>
        <div>
          <el-select size="large"
                     v-model="value4"
                     placeholder="请选择">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div> -->
      <div>
        <el-button @click="retrieval"
                   type="primary">搜 索</el-button>
      </div>
    </div>
    <div class="bb pl30 pr30"
         v-loading="listLoading">
      <div class="mb20">
        <el-table :data="tableData"
                  height="65vh"
                  style="width: 100%">
          <el-table-column label="ID"
                           width="100"
                           prop="id">
          </el-table-column>
          <el-table-column label="封禁账号"
                           width="280">
            <template #default="scope">
              <div class="flex">
                <img class="avatar"
                     :src="scope.row.user_info.avatar"
                     alt="">
                <div class="ml10 fz12">
                  <div>昵称：{{scope.row.user_info.nickname}}</div>
                  <div>手机号：{{scope.row.user_info.mobile}}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="IP"
                           width="180"
                           prop="ip">
            <template #default="scope">
              {{scope.row.ip?scope.row.ip:'-'}}
            </template>
          </el-table-column>
          <el-table-column label="封禁类型"
                           width="180">
            <template #default="scope">
              <el-tag v-if="scope.row.type==1"
                      type="info"
                      effect="dark">IP</el-tag>
              <el-tag v-if="scope.row.type==2"
                      type=""
                      effect="dark">视频</el-tag>
              <el-tag v-if="scope.row.type==3"
                      type="success"
                      effect="dark">发言</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="封禁日期"
                           width="180"
                           prop="createtime">
          </el-table-column>
          <el-table-column label="到期时间"
                           width="180">
            <template #default="scope">
              <div v-if="scope.row.expiration_time==-1">永久</div>
              <div v-else>{{scope.row.expiration_time}}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           align="right">
            <template #default="scope">
              <!-- <el-button type="primary"
                         size="small"
                         @click="changeTime(scope.$index, scope.row)">时长变更</el-button> -->
              <el-button type="danger"
                         size="small"
                         @click="relieve(scope.$index, scope.row)">解除封禁</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-pagination @current-change="handleCurrentChange"
                       :current-page="page"
                       :page-size="15"
                       layout="total, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="banTitle"
               v-model="banShow"
               width="30%">
      <div class="flex at_c">
        <div>选择时长：</div>
        <el-select size="large"
                   v-model="expiration_time"
                   placeholder="请选择封禁时间">
          <el-option v-for="item in timeArr"
                     :key="item.expiration_time"
                     :label="item.name"
                     :value="item.expiration_time">
          </el-option>
        </el-select>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="banCancel">取 消</el-button>
          <el-button type="primary"
                     @click="banConfirm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "../api/banList";
import pageTitle from "../common/pageTitle.vue";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      title: "封禁管理",
      search: "", // 关键词
      type: "", // 封禁类型
      banType: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "IP",
        },
        {
          value: "2",
          label: "视频",
        },
        {
          value: "3",
          label: "发言",
        },
      ],
      tableData: [],
      page: 1,
      total: 0,
      listLoading: false,

      banShow: false, // ban操作的时间选择
      timeArr: [
        {
          expiration_time: 60 * 30,
          name: "30分钟",
        },
        {
          expiration_time: 60 * 60 * 24,
          name: "24小时",
        },
        {
          expiration_time: 60 * 60 * 24 * 15,
          name: "15天",
        },
        {
          expiration_time: 60 * 60 * 24 * 30,
          name: "1个月",
        },
        {
          expiration_time: -1,
          name: "永久",
        },
      ],
      expiration_time: 60 * 30,
      banTitle: "",
      banObj: {},
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage
    this.getList(1);
  },
  methods: {
    onmessage(e) {

    },
    // 解除封禁
    relieve(index, row) {
      this.$confirm("确定解除封禁吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        switch (row.type) {
          case 1:
            this.relieveIP(row.ip);
            break;
          case 2:
            this.relieveVideo(row.user_info.id, row.user_info.type);
            break;
          case 3:
            this.relieveTalk(row.user_info.id, row.user_info.type);
            break;
        }
      });
    },
    async relieveIP(ip) {
      const res = await Service.openIp({
        ip,
      });
      this.$message.success(res.msg);
      this.getList(this.page);
    },
    async relieveVideo(user_id, type) {
      const res = await Service.changeVideoStatus({
        user_id,
        type,
        video_status: 1
      });
      this.$message.success(res.msg);
      this.getList(this.page);
    },
    async relieveTalk(user_id, type) {
      const res = await Service.changeUserChatStatus({
        user_id,
        type,
        chat_status: 1
      });
      this.$message.success(res.msg);
      this.getList(this.page);
    },
    changeTime(index, row) {
      this.banShow = true;
      var action = "";
      switch (row.type) {
        case 1:
          this.banTitle = "请选择封禁IP的时间";
          action = "ip";
          break;
        case 2:
          this.banTitle = "请选择封禁视频的时间";
          action = "video";
          break;
        case 3:
          this.banTitle = "请选择封禁发言的时间";
          action = "talk";
          break;
      }
      this.banObj = {
        action,
        ip: row.ip,
        user_id: row.user_info.id,
        type: row.user_info.type,
      };
    },
    banCancel() {
      this.banShow = false;
      this.banTitle = "";
      this.banObj = {};
    },
    banConfirm() {
      var banObj = this.banObj;
      switch (banObj.action) {
        case "ip":
          this.banIP(banObj.ip);
          break;
        case "video":
          this.banVideo(banObj.user_id, banObj.type);
          break;
        case "talk":
          this.banTalk(banObj.user_id, banObj.type);
          break;
      }
    },
    // 搜索操作
    retrieval() {
      this.getList(1);
    },
    // 获取列表
    async getList(page) {
      this.listLoading = true;
      const res = await Service.banList({
        page,
        search: this.search,
        type: this.type,
      });
      this.page = page;
      this.total = res.data.total;
      var tableData = res.data.data;
      this.tableData = tableData;
      this.listLoading = false;
    },
    // 切换分页
    handleCurrentChange(val) {
      this.getList(val);
      // console.log(`当前页: ${val}`);
    },
    // 修改权限
    changeAuth(index, row) {
      if (!row.authDisabled) {
        this.changeAuthApi(index, row);
      }
      this.tableData[index].authDisabled = true;
      setTimeout(() => {
        this.tableData[index].authDisabled = false;
      }, 800);
    },
    async changeAuthApi(index, row) {
      const res = await Service.setAuth({
        id: row.id,
        auth: row.auth == 1 ? 0 : 1,
      });
      // this.tableData[index].auth = this.tableData[index].auth == 1 ? 0 : 1;
      this.getList(this.page);
      this.$message.success(res.msg);
    },
    // 备注
    async modifyContent(id, remark) {
      const res = await Service.changeRemark({
        id,
        remark,
      });
      this.getList(this.page);
      this.$message.success(res.msg);
    },
  },
};
</script>

<style scoped>
.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form_cell /deep/.el-form-item {
  width: 50% !important;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
</style>