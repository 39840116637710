import request from "../utils/request";
// 获取会员抽奖记录
export const getList = (query) => {
  return request({
    url: "/service/lottery_draw/userLotteryDrawLog",
    method: "POST",
    data: query,
  });
};

// 发奖
export const awardPrizes = (query) => {
  return request({
    url: "/service/lottery_draw/setUserLotteryDrawLogStatus",
    method: "POST",
    data: query,
  });
};
