<template>
  <div class="pr video">
    <div id="J_prismPlayer" />
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {
    var player = new Aliplayer(
      {
        id: "J_prismPlayer",
        source: this.url,
        isLive: true,
        useH5Prism: true,
      },
      function (player) {
        console.error("The player is created.");
      }
    );
    player.on("error", (e) => {
      console.error("播放错误", e);
    });
  },
  methods: {},
};
</script>

<style scoped>
.video {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video_cell {
  /* width: 1350px; */
  width: 100%;
  /* height: 760px; */
}

.icon_video {
  position: absolute;
  width: 48px;
  height: 48px;
  left: 20px;
  bottom: 20px;
  cursor: pointer;
}

#J_prismPlayer {
  height: 100% !important;
}
</style>
