import { createRouter, createWebHashHistory } from "vue-router";
import Home from "@/common/Home";
import NotFound from "@/views/404";
import Login from "@/views/Login";
import Index from "@/views/Index";
import MyClient from "@/views/MyClient";
import TelephoneList from "@/views/TelephoneList";
import ChatRecord from "@/views/ChatRecord";
import Timetable from "@/views/Timetable";
import EnterpriseWechat from "@/views/EnterpriseWechat";
import ManagerList from "@/views/ManagerList";
import PhoneNumberList from "@/views/PhoneNumberList";
import DataStatistics from "@/views/DataStatistics";
import BanList from "@/views/BanList";
import Statistics from "@/views/Statistics";
import WinningList from "@/views/WinningList";
import CircleRecord from "@/views/CircleRecord";
import Tourist from "@/views/Tourist";

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/",
    component: Home,
    children: [
      {
        path: '/index',
        component: Index,
        meta: { title: '私聊界面' }
      },
      {
        path: '/myClient',
        component: MyClient,
        meta: { title: '我的客户' }
      },
      {
        path: '/tourist',
        component: Tourist,
        meta: { title: '游客列表' }
      },
      {
        path: '/telephoneList',
        component: TelephoneList,
        meta: { title: '电话资源列表' }
      },
      {
        path: '/chatRecord',
        component: ChatRecord,
        meta: { title: '聊天记录' }
      },
      {
        path: '/timetable',
        component: Timetable,
        meta: { title: '课表编辑' }
      },
      // {
      //   path: '/enterpriseWechat',
      //   component: EnterpriseWechat,
      //   meta: { title: '企业微信' }
      // }, 
      {
        path: '/managerList',
        component: ManagerList,
        meta: { title: '客户经理列表' }
      },
      {
        path: '/phoneNumberList',
        component: PhoneNumberList,
        meta: { title: '手机号列表' }
      },
      {
        path: '/dataStatistics',
        component: DataStatistics,
        meta: { title: '数据统计' }
      },
      {
        path: '/banList',
        component: BanList,
        meta: { title: '封禁管理' }
      },
      {
        path: '/statistics',
        component: Statistics,
        meta: { title: '统计数据' }
      },
      {
        path: '/winningList',
        component: WinningList,
        meta: { title: '中奖列表' }
      },
      {
        path: '/circleRecord',
        component: CircleRecord,
        meta: { title: '圈子记录' }
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { title: 'V策财经客服经理管理系统' }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: NotFound,
    meta: { title: "V策财经客服经理管理系统" },
  }

];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
