import request from "../utils/request";
// 获取封禁列表
export const banList = (query) => {
  return request({
    url: "/service/chat/banList",
    method: "POST",
    data: query,
  });
};
//封禁IP
export const banIp = (query) => {
  return request({
    url: "/service/chat/banIp",
    method: "POST",
    data: query,
  })
}
//解禁IP
export const openIp = (query) => {
  return request({
    url: "/service/chat/openIp",
    method: "POST",
    data: query,
  })
}
//修改用户视频状态
export const changeVideoStatus = (query) => {
  return request({
    url: "/service/chat/changeVideoStatus",
    method: "POST",
    data: query,
  })
}
//修改用户发言状态
export const changeUserChatStatus = (query) => {
  return request({
    url: "/service/chat/changeUserChatStatus",
    method: "POST",
    data: query,
  })
}
