<template>
  <div>
    <pageTitle :title="title"></pageTitle>
    <div class="head bb p30 flex fw_w">
      <div>
        <div>关键词</div>
        <div class="w300">
          <el-input clearable
                    size="large"
                    v-model="keywords"
                    placeholder="请输入关键词"></el-input>
        </div>
      </div>
      <div>
        <div>时间</div>
        <div>
          <el-date-picker format="YYYY-MM-DD"
                          value-format="x"
                          size="large"
                          v-model="time_range"
                          type="daterange"
                          align="right"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
      <div>
        <div>有效会话时间</div>
        <div>
          <el-date-picker format="YYYY-MM-DD"
                          value-format="x"
                          size="large"
                          v-model="valid_time_range"
                          type="daterange"
                          align="right"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
      <div>
        <div>归属</div>
        <div>
          <el-select filterable
                     size="large"
                     v-model="service_id"
                     placeholder="请选择">
            <el-option v-for="item in serviceArr"
                       :key="item.id"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <div>链接</div>
        <div>
          <el-select filterable
                     size="large"
                     v-model="url"
                     placeholder="请选择">
            <el-option label="全部"
                       value="">
            </el-option>
            <el-option v-for="item in urlArr"
                       :key="item.id"
                       :label="item.name"
                       :value="item.name">
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <el-button @click="retrieval"
                   type="primary">搜 索</el-button>
      </div>
    </div>
    <div class="bb pl30 pr30">
      <div v-loading="userListLoading">
        <div class="mb20">
          <el-table :data="tableData"
                    height="60vh"
                    style="width: 100%">
            <el-table-column show-overflow-tooltip
                             label="昵称"
                             width="120"
                             prop="nickname">
            </el-table-column>
            <el-table-column label="来源"
                             width="200"
                             prop="referer">
            </el-table-column>
            <el-table-column label="计划"
                             width="100"
                             prop="jihua">
            </el-table-column>
            <el-table-column label="关键词id"
                             width="100"
                             prop="key_id">
            </el-table-column>
            <el-table-column show-overflow-tooltip
                             label="关键词"
                             width=""
                             prop="keywords">
            </el-table-column>
            <el-table-column label="IP"
                             width="150"
                             prop="joinip">
            </el-table-column>
            <el-table-column label="注册时间"
                             width="170"
                             prop="createtime">
            </el-table-column>
            <el-table-column label="归属"
                             width="120">
              <template #default="scope">
                {{scope.row.service.id?scope.row.service.nickname:'-'}}
              </template>
            </el-table-column>

          </el-table>
        </div>
        <div>
          <el-pagination @current-change="handleCurrentChange"
                         :current-page="page"
                         :page-size="15"
                         layout="total, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 添加假人 -->
    <el-dialog width="420px"
               v-model="addUserFormShow"
               title="添加假人">
      <el-form :model="addUserForm"
               ref="addUserForm"
               :rules="addUserRules">
        <el-form-item class="w222"
                      size="large"
                      label="昵称"
                      :label-width="formLabelWidth"
                      prop="nickname">
          <el-input v-model="addUserForm.nickname"
                    autocomplete="off" />
        </el-form-item>
        <el-form-item class="w222"
                      size="large"
                      label="时间"
                      :label-width="formLabelWidth"
                      prop="live_expiration_time">
          <el-date-picker v-model="addUserForm.live_expiration_time"
                          type="date"
                          placeholder="选择等级到期时间"
                          format="YYYY-MM-DD"
                          value-format="x">
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item size="large"
                      label="权限组"
                      :label-width="formLabelWidth">
          <el-select v-model="form.region"
                     placeholder="请选择">
            <el-option label="Zone No.1"
                       value="shanghai" />
            <el-option label="Zone No.2"
                       value="beijing" />
          </el-select>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addUserFormShow = false">取消</el-button>
          <el-button type="primary"
                     @click="submitForm('addUserForm')">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑资料 -->
    <el-dialog width="680px"
               v-model="editFormShow"
               title="编辑资料">
      <el-form :model="editForm"
               ref="editForm">
        <div>
          <div class="form_cell_title">基础信息</div>
          <div class="form_cell">
            <el-form-item size="large"
                          label="昵称"
                          :label-width="formLabelWidth1">
              <el-input v-model="editForm.nickname"
                        autocomplete="off" />
            </el-form-item>
            <el-form-item size="large"
                          label="姓名"
                          :label-width="formLabelWidth1">
              <el-input v-model="editForm.username"
                        autocomplete="off" />
            </el-form-item>
            <el-form-item size="large"
                          label="验证手机"
                          :label-width="formLabelWidth1">
              <el-input disabled
                        v-model="editForm.mobile"
                        autocomplete="off" />
            </el-form-item>
            <el-form-item size="large"
                          label="QQ号"
                          :label-width="formLabelWidth1">
              <el-input v-model="editForm.qq"
                        autocomplete="off" />
            </el-form-item>
            <el-form-item size="large"
                          label="登录密码"
                          :label-width="formLabelWidth1">
              <el-input placeholder="若不修改请留空"
                        v-model="editForm.password"
                        autocomplete="off" />
            </el-form-item>
          </div>
        </div>
        <div>
          <div class="form_cell_title">权限信息</div>
          <div class="form_cell w202">
            <el-form-item class="date"
                          size="large"
                          label="直播"
                          :label-width="formLabelWidth1">
              <el-date-picker v-model="editForm.live_expiration_time"
                              type="date"
                              placeholder="选择直播到期时间"
                              format="YYYY-MM-DD"
                              value-format="x">
              </el-date-picker>
            </el-form-item>
            <el-form-item size="large"
                          label="客户经理"
                          :label-width="formLabelWidth1">
              <el-select filterable
                         clearable
                         size="large"
                         v-model="editForm.service_id"
                         placeholder="请选择客户经理">
                <el-option v-for="item in serviceList"
                           :key="item.id"
                           :label="item.nickname"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item size="large"
                        label="备注"
                        :label-width="formLabelWidth1">
            <el-input rows="5"
                      resize="none"
                      type="textarea"
                      v-model="editForm.bio"
                      autocomplete="off" />
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editFormShow = false">取消</el-button>
          <el-button type="primary"
                     @click="submitForm('editForm')">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 圈子管理 -->
    <el-dialog width="640px"
               v-model="circleShow"
               title="圈子管理">
      <div class="flex jc_b">
        <div>
          <div>
            <div class="h500"
                 v-loading="tableLoading">
              <el-table row-key="id"
                        :data="circleList"
                        @selection-change="handleSelectionChange"
                        ref="multipleTable">
                <!-- <el-table-column type="selection"
                                 width="40"
                                 reserve-selection /> -->
                <el-table-column align="center"
                                 label="头像"
                                 width="80">
                  <template #default="scope">
                    <div class="flex at_c jc_c">
                      <img class="avatar"
                           :src="scope.row.avatar"
                           alt="" />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="title"
                                 label="名称"
                                 width="240"
                                 show-overflow-tooltip />
                <el-table-column align="center"
                                 label="状态"
                                 width="140">
                  <template #default="scope">
                    <div class="flex at_c jc_c">
                      <el-button @click="changeCircleStatus(scope.row.teacher_id,scope.row.status)"
                                 v-if="scope.row.status"
                                 type="primary">已加入</el-button>
                      <el-button @click="changeCircleStatus(scope.row.teacher_id,scope.row.status)"
                                 v-else
                                 type="danger">未加入</el-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center"
                                 label="操作"
                                 width="140">
                  <template #default="scope">
                    <div class="flex at_c jc_c">
                      <el-button @click="changeCircleStatus(scope.row.teacher_id,scope.row.status,1)"
                                 v-if="scope.row.status"
                                 type="danger">彻底删除</el-button>
                      <div v-if="!scope.row.status">-</div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- <div class="mt10">
            <el-pagination @current-change="handleCurrentChange"
                           v-model:current-page="currentPage"
                           layout="prev, pager, next, jumper"
                           :page-size="15"
                           :total="total">
            </el-pagination>
          </div> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "../api/myClient";
import * as ServiceIndex from "../api/index";
import pageTitle from "../common/pageTitle.vue";
import iNotify from "title-notify";
// import Notify from "@wcjiang/notify";
// import { useStore } from 'vuex'
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      title: "游客列表",
      keywords: "", // 关键词
      time_range: [], // 时间范围
      valid_time_range: [], // 有效会话时间范围
      service_id: 0, // 0全部客服
      serviceArr: [
        {
          id: 0,
          nickname: "全部",
        },
      ],
      url: "",
      urlArr: [],
      userListLoading: false, // 会员列表加载状态
      tableData: [],
      page: 1,
      total: 0,
      addUserFormShow: false, // 添加假人表单显示状态
      addUserForm: {
        nickname: "",
        live_expiration_time: "",
      }, // 添加假人表单
      addUserRules: {
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        live_expiration_time: [
          { required: true, message: "请选择等级到期时间", trigger: "change" },
        ],
      }, // 添加假人表单验证规则
      editFormShow: false, // 编辑资料表单显示状态
      editForm: {
        id: null,
        nickname: "",
        username: "",
        mobile: "",
        qq: "",
        password: "",
        live_expiration_time: "",
        service_id: "",
        bio: "",
      }, // 编辑资料表单
      formLabelWidth: "60px",
      formLabelWidth1: "100px",
      teacherList: [], // 研究员列表
      serviceList: [], // 客服列表
      is_service_admin: localStorage.getItem("is_service_admin"), // 0非管理员1管理员
      circleShow: false,
      circleList: [],
      currentUserId: null, // 当前会员id
      tableLoading: false,
      serviceId: null, // 当前账号客服id
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    // const store = useStore()
    // store.state.ws.onmessage = this.onmessage
    // $socketIo.connectSocketInit({
    //   room_id: 1,
    //   type: "join_room_send",
    // });
    this.getBaseInfo();
    this.getUserInfo();
    this.getVisitorUserList(1);

    // setInterval(()=>{
    //   document.title = '您有新消息未读'
    //   setTimeout(()=>{
    //     document.title = 'AAAA'
    //   },500)
    // },1000)

    //推荐下面写法
    // const aa = new iNotify({
    //     message: '有消息了。',//标题
    //     effect: 'flash', // flash | scroll 闪烁还是滚动
    //     //可选播放声音
    //     audio:{
    //         //可以使用数组传多种格式的声音文件
    //         file: ['msg.mp4','msg.mp3','msg.wav']
    //         //下面也是可以的哦
    //         //file: 'msg.mp4'
    //     },
    //     //标题闪烁，或者滚动速度
    //     interval: 1000,
    //     //可选，默认绿底白字的  Favicon
    //     // updateFavicon:{
    //     //     // favicon 字体颜色
    //     //     textColor: "#fff",
    //     //     //背景颜色，设置背景颜色透明，将值设置为“transparent”
    //     //     backgroundColor: "#2F9A00"
    //     // },
    //     //可选chrome浏览器通知，默认不填写就是下面的内容
    //     notification:{
    //         title:"通知！",//设置标题
    //         icon:"",//设置图标 icon 默认为 Favicon
    //         body:'您来了一条新消息'//设置消息内容
    //     }
    // })

    // console.log('AA@',aa)
    // Notification.requestPermission(function (status) {
    //   console.log('BB@',status)
    //   if (status === "granted") {
    //       //  var n = new Notification("您有新消息未读");
    //    } else {
    //         alert("Hi!");
    //    }
    // });
    // setTimeout(()=>{
    //   new Notification("您有新消息未读");
    // },5000)
    // const notify = new Notify({
    //   message: "There is message.", // page title.
    //   effect: "flash", // flash | scroll, Flashing or scrolling
    //   openurl: "https://github.com/jaywcjlove/iNotify", // Click on the pop-up window to open the connection address
    //   onclick: () => {
    //     // Click on the pop-up window trip event
    //     // Programmatically closes a notification.
    //     notify.close();
    //     console.log("---");
    //   },
    //   // Optional playback sound
    //   audio: {
    //     // You can use arrays to pass sound files in multiple formats.
    //     file: ["msg.mp4", "msg.mp3", "msg.wav"],
    //     // The following is also work.
    //     // file: 'msg.mp4'
    //   },
    //   // Title flashing, or scrolling speed
    //   interval: 1000,
    //   disableFavicon: false, // Optional, default false, if true, No longer overwrites the original favicon
    //   // Optional, default green background white text. Favicon
    //   updateFavicon: {
    //     // favicon font color
    //     textColor: "#fff",
    //     // Background color, set the background color to be transparent, set the value to "transparent"
    //     backgroundColor: "#2F9A00",
    //   },
    //   // Optional chrome browser notifications，
    //   // The default is not to fill in the following content
    //   notification: {
    //     title: "Notification!", // Set notification title
    //     icon: "", // Set notification icon, The default is Favicon
    //     body: "You have a new message!", // Set message content
    //   },
    // });

    // notify.player();
  },
  methods: {
    onmessage(e) {
      // console.log('myclient-onmessage',JSON.parse(e.data))
    },
    // 修改会员V券
    handleMoney(index, row) {
      let that = this;
      that
        .$prompt("请输入变动值（需要带加减号）", "V券变动", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          // inputPattern: /^(([1-9][0-9]*|0)|(([0]\\.\\d{1,2}|[1-9][0-9]*\\.\\d{1,2})))$/,
          // inputErrorMessage: '变动值不合法'
        })
        .then(({ value }) => {
          that.changeMoney(row, value);
        });
    },
    async changeMoney(row, money) {
      const res = await Service.changeMoney({
        id: row.id,
        money,
        remark: "客服修改V券",
      });
      // console.log("客服修改V券", res);
      this.$message.success(res.msg);
      this.getVisitorUserList(this.page);
    },
    // 搜索操作
    retrieval() {
      this.getVisitorUserList(1);
    },
    // 获取基础信息
    async getBaseInfo() {
      const res = await ServiceIndex.baseInfo();
      // console.log("获取基础信息", res);
      this.teacherList = res.data.teacherList;
      this.serviceList = res.data.serviceList;
      this.serviceArr = this.serviceArr.concat(res.data.serviceList);
      this.urlArr = res.data.urlArr;
    },
    // 获取账号信息
    async getUserInfo() {
      const res = await ServiceIndex.getServiceIndex();
      this.serviceId = res.data.userinfo.user_id;
      console.log("serviceId", res.data.userinfo.user_id);
      console.log("serviceId", this.serviceId);
    },
    // 获取我的会员列表
    async getVisitorUserList(page) {
      this.userListLoading = true;
      var time_range0 = this.time_range ? [...this.time_range] : [];
      var valid_time_range0 = this.valid_time_range
        ? [...this.valid_time_range]
        : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }
      if (valid_time_range0.length) {
        valid_time_range0[0] = valid_time_range0[0] / 1000;
        valid_time_range0[1] = valid_time_range0[1] / 1000;
      }
      const res = await Service.getVisitorUserList({
        page,
        keywords: this.keywords,
        time_range: time_range0,
        valid_time_range: valid_time_range0,
        service_id: this.service_id,
        url: this.url,
      });
      // console.log("我的会员列表", res);
      this.page = page;
      this.total = res.data.total;
      this.tableData = res.data.data;
      this.userListLoading = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (formName) {
            case "addUserForm":
              this.addUser();
              break;
            case "editForm":
              this.profile();
              break;
          }
        }
      });
    },
    // 添加假人
    async addUser() {
      var addUserForm = this.addUserForm;
      const res = await Service.fillUser({
        nickname: addUserForm.nickname,
        live_expiration_time: addUserForm.live_expiration_time / 1000,
      });
      // console.log("添加假人", res);
      this.$message.success(res.msg);
      this.addUserFormShow = false;
      this.getVisitorUserList(this.page);
      this.$refs["addUserForm"].resetFields();
    },
    // 修改会员信息
    async profile() {
      var editForm = this.editForm;
      var param = {
        id: editForm.id,
        nickname: editForm.nickname,
        username: editForm.username,
        qq: editForm.qq,
        password: editForm.password,
        live_expiration_time: editForm.live_expiration_time / 1000,
        service_id: editForm.service_id,
        bio: editForm.bio,
      };
      const res = await Service.profile(param);
      // console.log("修改会员信息", res);
      this.$message.success(res.msg);
      this.editFormShow = false;
      this.$refs["editForm"].resetFields();
      this.getVisitorUserList(this.page);
    },
    handleEdit(index, row) {
      this.editFormShow = true;
      console.log("handleEdit", row.service_id);
      this.editForm = {
        id: row.id,
        nickname: row.nickname,
        username: row.username,
        mobile: row.mobile,
        qq: row.qq,
        password: "",
        live_expiration_time: row.live_expiration_time * 1000,
        service_id: row.service_id,
        bio: row.bio,
      };
    },
    handleDelete(index, row) {
      // console.log(index, row);
    },
    handleCurrentChange(val) {
      this.getVisitorUserList(val);
      // console.log(`当前页: ${val}`);
    },
    async handleCircle(index, row) {
      this.tableLoading = true;
      const res = await Service.getUserCircleList({
        id: row.id,
      });
      // console.log("获取会员圈子信息", res);
      this.circleList = res.data;
      this.currentUserId = row.id;
      this.circleShow = true;
      this.tableLoading = false;
    },
    changeCircleStatus(teacherId, status, is_delete) {
      var text = status ? "离开" : "加入";
      var text0 = is_delete ? "彻底删除" : text;
      this.$confirm("确认将该会员" + text0 + "该圈子吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.changeCircleStatusApi(teacherId, status, is_delete);
      });
    },
    async changeCircleStatusApi(teacherId, status, is_delete) {
      let res = null;
      var circleList = this.circleList;
      if (status) {
        //离开圈子
        res = await Service.leaveCircle({
          id: this.currentUserId,
          teacher_id: teacherId,
          is_delete,
        });
      } else {
        //加入圈子
        res = await Service.joinCircle({
          id: this.currentUserId,
          teacher_id: teacherId,
        });
      }
      for (const item of circleList) {
        if (item.teacher_id == teacherId) {
          item.status = !item.status;
        }
      }
      this.circleList = circleList;
      // console.log("变更圈子状态", res);
      this.$message.success(res.msg);
    },
  },
};
</script>

<style scoped>
.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.w202 /deep/.el-input__inner {
  width: 202px !important;
}

.w202 .date /deep/.el-input__suffix {
  right: 33px !important;
}

.avatar {
  width: 45px;
  height: 45px;
}
</style>