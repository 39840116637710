<template>
  <div class="sidebar">
    <el-menu :default-active="onRoutes"
             class="el-menu-vertical-demo"
             :collapse="collapse"
             background-color="#26292C"
             active-text-color="#5188F4"
             router>
      <div class="cell">
        <img class="head_img"
             src="@/assets/img/menu/head_img.png"
             alt="">
      </div>
      <div v-for="(item,index) in items"
           :key="index">
        <el-menu-item :index="item.index"
                      v-if="item.isShow">
          <div class="pr">
            <div class="red_point"
                 v-if="item.index=='phoneNumberList' && tipsNum"></div>
            <img class="menu_icon"
                 :src="onRoutes==item.index?item.icon_active:item.icon"
                 alt="">
          </div>
          <template #title>{{item.name}}</template>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>

<script>
import * as Service from "@/api/index";
export default {
  data() {
    return {
      collapse: true,
      items: [
        {
          index: "index",
          icon: require("@/assets/img/menu/icon_siliao.png"),
          icon_active: require("@/assets/img/menu/icon_siliao_active.png"),
          name: "私聊界面",
          isShow: true,
        },
        {
          index: "myClient",
          icon: require("@/assets/img/menu/icon_wodekehu.png"),
          icon_active: require("@/assets/img/menu/icon_wodekehu_active.png"),
          name: "我的客户",
          isShow: true,
        },
        {
          index: "tourist",
          icon: require("@/assets/img/menu/icon_wodekehu.png"),
          icon_active: require("@/assets/img/menu/icon_wodekehu_active.png"),
          name: "游客列表",
          isShow: true,
        },
        {
          index: "dataStatistics",
          icon: require("@/assets/img/menu/icon_zaixiantongji.png"),
          icon_active: require("@/assets/img/menu/icon_zaixiantongji_active.png"),
          name: "数据统计",
          isShow: true,
        },
        {
          index: "telephoneList",
          icon: require("@/assets/img/menu/icon_dianhuaziyuan.png"),
          icon_active: require("@/assets/img/menu/icon_dianhuaziyuan_active.png"),
          name: "电话资源",
          isShow: false,
        },
        {
          index: "chatRecord",
          icon: require("@/assets/img/menu/icon_liaotianjilv.png"),
          icon_active: require("@/assets/img/menu/icon_liaotianjilv_active.png"),
          name: "聊天记录",
          isShow: true,
        },
        {
          index: "banList",
          icon: require("@/assets/img/menu/icon_ipjiefeng.png"),
          icon_active: require("@/assets/img/menu/icon_ipjiefeng_active.png"),
          name: "封禁管理",
          isShow: true,
        },
        // {
        //   index: "7",
        //   icon: require("@/assets/img/menu/icon_jieshou.png"),
        //   icon_active: require("@/assets/img/menu/icon_jieshou_active.png"),
        //   name: "趋势交易圈-接收人员列表",
        // },
        {
          index: "phoneNumberList",
          icon: require("@/assets/img/menu/icon_shoujihao.png"),
          icon_active: require("@/assets/img/menu/icon_shoujihao_active.png"),
          name: "手机号列表",
          isShow: true,
        },
        {
          index: "managerList",
          icon: require("@/assets/img/menu/icon_kehujingli.png"),
          icon_active: require("@/assets/img/menu/icon_kehujingli_active.png"),
          name: "客户经理列表",
          isShow: localStorage.getItem("is_service_admin") == 1 ? true : false,
        },
        // {
        //   index: "enterpriseWechat",
        //   icon: require("@/assets/img/menu/icon_qiyeweixin.png"),
        //   icon_active: require("@/assets/img/menu/icon_qiyeweixin_active.png"),
        //   name: "企业微信",
        //   isShow: true,
        // },
        // {
        //   index: "11",
        //   icon: require("@/assets/img/menu/icon_xuanchuantu.png"),
        //   icon_active: require("@/assets/img/menu/icon_xuanchuantu_active.png"),
        //   name: "宣传图",
        // },
        {
          index: "timetable",
          icon: require("@/assets/img/menu/icon_kebiaobianji.png"),
          icon_active: require("@/assets/img/menu/icon_kebiaobianji_active.png"),
          name: "课表编辑",
          isShow: true,
        },
        {
          index: "statistics",
          icon: require("@/assets/img/menu/icon_data.png"),
          icon_active: require("@/assets/img/menu/icon_data_active.png"),
          name: "统计数据",
          isShow: localStorage.getItem("is_service_admin") == 1 ? true : false,
        },
        {
          index: "winningList",
          icon: require("@/assets/img/menu/icon_zhongjiang.png"),
          icon_active: require("@/assets/img/menu/icon_zhongjiang_active.png"),
          name: "中奖列表",
          isShow: false,
        },
        {
          index: "circleRecord",
          icon: require("@/assets/img/menu/icon_quanzi.png"),
          icon_active: require("@/assets/img/menu/icon_quanzi_active.png"),
          name: "圈子记录",
          isShow: true,
        },
      ],
      tipsNum: 0,
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
  },
  created() {},
  mounted() {
    // this.getServiceTip();
    // this.timer = setInterval(() => {
    //   this.getServiceTip();
    // }, 1000 * 60);
  },
  methods: {
    // 获取客服提示
    async getServiceTip() {
      const res = await Service.getServiceTip();
      // console.log("获取客服提示", res);
      this.tipsNum = res.data.mobileCount;
    },
  },
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 62px;
  overflow-y: scroll;
}

.cell {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.head_img {
  width: 46px;
  height: 46px;
}

.menu_icon {
  width: 32px;
  height: 32px;
  position: relative;
  left: -5.5px;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}

.sidebar > ul {
  height: 100%;
  overflow-y: auto;
}

.el-menu--collapse {
  width: 100%;
}

.el-menu-item * {
  vertical-align: middle !important;
}

.el-menu-item.is-active {
  background-color: #5188f4;
}

.red_point {
  position: absolute;
  top: 10px;
  right: -5px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: red;
}
</style>
