<template>
  <div>
    <pageTitle :title="title"></pageTitle>
    <div class="head bb p30 flex fw_w">
      <div>
        <div>关键词</div>
        <div class="w300">
          <el-input clearable
                    size="large"
                    v-model="search"
                    placeholder="请输入"></el-input>
        </div>
      </div>
      <div>
        <div>发送人</div>
        <div class="w300">
          <el-input size="large"
                    v-model="send_user"
                    placeholder="请输入昵称/手机号/真实姓名"></el-input>
        </div>
      </div>
      <div>
        <div>接收人</div>
        <div class="w300">
          <el-input size="large"
                    v-model="accept_user"
                    placeholder="请输入昵称/手机号/真实姓名"></el-input>
        </div>
      </div>
      <div>
        <div>渠道</div>
        <div>
          <el-select v-model="channel_id"
                     size="large"
                     placeholder="请选择">
            <el-option v-for="item in channelArr"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- <div>
        <div>来源</div>
        <div>
          <el-select size="large"
                     v-model="value4"
                     placeholder="请选择">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div> -->
      <div>
        <div>时间</div>
        <div>
          <el-date-picker format="YYYY-MM-DD"
                          value-format="x"
                          size="large"
                          v-model="time_range"
                          type="daterange"
                          align="right"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
      <!-- <div>
        <div>部门</div>
        <div>
          <el-select size="large"
                     v-model="value4"
                     placeholder="请选择">
            <el-option v-for="item in options"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div> -->
      <div>
        <el-button @click="retrieval"
                   type="primary">搜 索</el-button>
      </div>
    </div>
    <div class="bb pl30 pr30"
         v-loading="listLoading">
      <div class="mb20">
        <el-table :data="tableData"
                  height="65vh"
                  style="width: 100%">
          <el-table-column label="ID"
                           width="120"
                           prop="id">
          </el-table-column>
          <el-table-column label="发送人"
                           width="180"
                           prop="user.nickname">
          </el-table-column>
          <el-table-column label="发送时间"
                           width="180"
                           prop="createtime_text">
          </el-table-column>
          <el-table-column label="接收人"
                           width="180"
                           prop="to_user.nickname">
          </el-table-column>
          <el-table-column show-overflow-tooltip
                           label="内容"
                           prop="content">
          </el-table-column>
          <el-table-column label="关键词"
                           width="150"
                           prop="name">
          </el-table-column>
          <el-table-column label="渠道"
                           width="120"
                           prop="user.user_type">
          </el-table-column>
          <el-table-column label="IP"
                           width="140"
                           prop="to_user.joinip">
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-pagination @current-change="handleCurrentChange"
                       :current-page="page"
                       :page-size="15"
                       layout="total, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "../api/chatRecord";
import pageTitle from "../common/pageTitle.vue";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      title: "聊天记录",
      search: "", // 关键词
      time_range: [], // 时间
      send_user: null, // 发送人
      accept_user: null, // 接收人
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      tableData: [],
      page: 1,
      total: 0,
      listLoading: false,
      channel_id: null,
      channelArr: [],
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage
    this.getBaseInfo()
    this.getChatList(1);
  },
  methods: {
    onmessage(e) {

    },
    // 获取推广分析基础信息
    async getBaseInfo() {
      const res = await Service.getBaseInfo();
      this.channelArr = res.data.channelArr;
    },
    // 搜索操作
    retrieval() {
      this.getChatList(1);
    },
    async getChatList(page) {
      this.listLoading = true;
      var time_range0 = this.time_range ? [...this.time_range] : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }
      const res = await Service.getChatList({
        page,
        search: this.search,
        time_range: time_range0,
        send_user: this.send_user,
        accept_user: this.accept_user,
        channel_id: this.channel_id
      });
      // console.log("聊天记录列表", res);
      this.page = page;
      this.total = res.data.list.total;
      this.tableData = res.data.list.data;
      this.listLoading = false;
    },
    handleCurrentChange(val) {
      this.getChatList(val);
      // console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style scoped>
.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form_cell /deep/.el-form-item {
  width: 50% !important;
}
</style>