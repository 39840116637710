<template>
  <div>
    <pageTitle :title="title"></pageTitle>
    <div class="bb pt30 pl30">
      <el-tabs v-model="activeName"
               type="card"
               @tab-click="handleClick">
        <el-tab-pane label="网销手机号"
                     name="first">
        </el-tab-pane>
        <el-tab-pane label="电销手机号"
                     name="second">
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="head bb p30 flex fw_w">
      <div>
        <div>关键词</div>
        <div class="w300">
          <el-input clearable
                    size="large"
                    v-model="search"
                    placeholder="请输入昵称/手机号/真实姓名"></el-input>
        </div>
      </div>
      <div>
        <div>时间</div>
        <div>
          <el-date-picker format="YYYY-MM-DD"
                          value-format="x"
                          size="large"
                          v-model="time_range"
                          type="daterange"
                          align="right"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
      <div>
        <div>状态</div>
        <div>
          <el-select filterable
                     size="large"
                     v-model="state"
                     placeholder="请选择">
            <el-option v-for="item in stateList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <div>来源</div>
        <div>
          <el-select filterable
                     size="large"
                     v-model="source"
                     placeholder="请选择">
            <el-option v-for="item in sourceList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <div>归属</div>
        <div>
          <el-select filterable
                     size="large"
                     v-model="service"
                     placeholder="请选择">
            <el-option v-for="item in serviceList"
                       :key="item.id"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <el-button @click="retrieval"
                   type="primary">搜 索</el-button>
      </div>
    </div>
    <div class="bb pl30 pr30"
         v-loading="phoneListLoading">
      <div class="mb20">
        <el-table :data="tableData"
                  height="65vh"
                  style="width: 100%">
          <el-table-column label="真实姓名"
                           width="130"
                           prop="name">
          </el-table-column>
          <el-table-column label="手机号"
                           width="130"
                           prop="phone">
          </el-table-column>
          <el-table-column label="归属"
                           width="130"
                           prop="service">
          </el-table-column>
          <el-table-column label="来源"
                           width="100"
                           prop="source_title">
          </el-table-column>
          <el-table-column label="反馈内容(点击填写)"
                           width="">
            <template #default="scope">
              <el-input @blur="modifyContent(scope.row.id,scope.row.state,scope.row.remarks)"
                        v-model="scope.row.remarks"
                        placeholder="请输入内容"></el-input>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip
                           label="URL"
                           width="220"
                           prop="url">
          </el-table-column>
          <el-table-column label="分配时间"
                           width="180"
                           prop="create_time">
          </el-table-column>
          <el-table-column label="状态"
                           width="90">
            <template #default="scope">
              <div class="tag"
                   style="background-color: #409EFF;"
                   v-if="scope.row.state==0">未拨打</div>
              <div class="tag"
                   style="background-color: #67C23A;"
                   v-if="scope.row.state==2">未拨通</div>
              <div class="tag"
                   style="background-color: #909399;"
                   v-if="scope.row.state==4">未添加</div>
              <div class="tag"
                   style="background-color: #F56C6C;"
                   v-if="scope.row.state==6">未注册</div>
              <div class="tag"
                   style="background-color: #E6A23C;"
                   v-if="scope.row.state==7">已注册</div>
              <div class="tag"
                   style="background-color: #E052FB;"
                   v-if="scope.row.state==9">已开户</div>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           width="100">
            <template #default="scope">
              <el-button v-if="scope.row.state !=6 && scope.row.state !=7 && scope.row.state !=9"
                         size="small"
                         @click="handleFeedback(scope.$index, scope.row)">反馈</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-pagination @current-change="handleCurrentChange"
                       :current-page="page"
                       :page-size="15"
                       layout="total, prev, pager, next, jumper"
                       :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
import pageTitle from "../common/pageTitle.vue";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      title: "电话资源",
      activeName: "first",
      search: "", // 关键词
      time_range: [], // 时间
      state: null, // 状态
      source: null, // 来源
      service: null, // 归属

      stateList: [
        {
          id: null,
          name: "全部",
        },
        {
          id: 0,
          name: "未拨打",
        },
        {
          id: 2,
          name: "未拨通",
        },
        {
          id: 4,
          name: "未添加",
        },
        {
          id: 6,
          name: "未注册",
        },
        {
          id: 7,
          name: "已注册",
        },
        {
          id: 9,
          name: "已开户",
        },
      ], // 状态列表
      sourceList: [
        {
          id: null,
          name: "全部",
        },
      ], // 来源列表
      serviceList: [
        {
          id: null,
          nickname: "全部",
        },
      ], // 归属列表
      phoneListLoading: false,
      tableData: [],
      page: 1,
      total: 0,
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getBaseInfo();
    this.getPhoneList(1);
  },
  methods: {
    onmessage(e) {},
    // tab切换
    handleClick(tab, event) {
      this.search = "";
      this.time_range = [];
      this.state = null;
      this.source = null;
      this.service = null;
      this.tableData = []
      this.getPhoneList(1)
      // if (this.activeName == "first") {
      // } else {
      // }
    },
    // 反馈内容提交
    async modifyContent(id, state, content) {
      const res = await Service.phoneStatus({
        id,
        state,
        content,
        type: this.activeName == "first" ? 1 : 2,
      });
      this.getPhoneList(this.page);
      this.$message.success(res.msg);
    },
    // 搜索操作
    retrieval() {
      this.getPhoneList(1);
    },
    // 获取基本信息
    async getBaseInfo(page) {
      this.phoneListLoading = true;
      const res = await Service.baseInfo();
      // console.log("获取基本信息", res);
      this.sourceList = this.sourceList.concat(res.data.sourceList);
      this.serviceList = this.serviceList.concat(res.data.serviceList);
    },
    // 获取电话列表
    async getPhoneList(page) {
      this.phoneListLoading = true;
      var time_range0 = this.time_range ? [...this.time_range] : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }
      const res = await Service.phoneList({
        page,
        search: this.search,
        time_range: time_range0,
        state: this.state,
        source: this.source,
        service: this.service,
        type: this.activeName == "first" ? 1 : 2,
      });
      // console.log("getPhoneList", res);
      this.page = page;
      this.total = res.data.data.total;
      this.tableData = res.data.data.data;
      this.phoneListLoading = false;
    },
    handleFeedback(index, row) {
      let that = this;
      var content = "",
        confirmButtonText = "",
        cancelButtonText = "",
        state = null;
      switch (row.state) {
        case 0:
          //未拨打
          content = "是否拨打电话？";
          confirmButtonText = "已拨打";
          cancelButtonText = "未拨打";
          state = 2;
          break;
        case 2:
          //未拨通
          content = "是否拨通电话？";
          confirmButtonText = "已拨通";
          cancelButtonText = "未拨通";
          state = 4;
          break;
        case 4:
          //未添加
          content = "是否已添加联系方式电话？";
          confirmButtonText = "已添加";
          cancelButtonText = "未添加";
          state = 6;
          break;
      }
      that
        .$confirm(content, "信息", {
          confirmButtonText,
          cancelButtonText,
          type: "warning",
          showClose: false,
          closeOnClickModal: false,
        })
        .then(() => {
          that.phoneStatus(row.id, state);
        })
        .catch(() => {});
      // console.log(index, row);
    },
    async phoneStatus(id, state) {
      const res = await Service.phoneStatus({
        id,
        state,
        type: this.activeName == "first" ? 1 : 2,
      });
      this.getPhoneList(this.page);
      this.$message.success(res.msg);
    },
    handleCurrentChange(val) {
      this.getPhoneList(val);
      // console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style scoped>
.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form_cell /deep/.el-form-item {
  width: 50% !important;
}

.tag {
  font-size: 12px;
  padding: 0 9px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
</style>