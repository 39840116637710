import request from "../utils/request";

export const login = (query) => {
  return request({
    url: "/service/user/login",
    method: "POST",
    data: query,
  });
};

// 获取违禁词列表
export const getBanWord = (query) => {
  return request({
    url: "/service/common/getBanWord",
    method: "POST",
    data: query,
  });
};
