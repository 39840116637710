import axios from "axios";
import { ElMessage } from "element-plus";
import router from "@/router";

const service = axios.create({
  baseURL: "https://api.i-vce.com/", // api的base_url
  timeout: 30000, // 请求超时时间
  withCredentials: true,
});

service.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("vce-token-service")) {
      config.headers.token = localStorage.getItem("vce-token-service");
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject();
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.data.code == 1) {
      // if (response.headers.authorization) {
      //   localStorage.setItem("token", response.headers.authorization);
      // }
      return response.data;
    } else {
      if (response.data.code == 4001) {
        router.push("/login");
      } else {
        ElMessage.error(response.data.msg);
      }
      return Promise.reject(response);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
