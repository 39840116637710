<template>
  <div class="bb p15"
       style="height: 100%;">
    <div class="flex at_c mb10">
      <div @click="getCircleUserList(item.uid)"
           class="mr20 can"
           :class="{active: uid==item.uid}"
           v-for="(item,index) in circleList"
           :key="index">
        {{item.title}}
      </div>
    </div>
    <div v-loading="loading" style="height: calc(100% - 30px);">
      <div class="total_text">当前在线总人数：<b>{{total}}</b></div>
      <div class="user_list flex fw_w at_s">
        <div class="circle_user_cell"
             v-for="(item, index) in onlineList"
             :key="index">
          <div>
            <div class="pr bb"
                 :class="{ biankuang: item.user.is_firm_offer }">
              <img class="avatar"
                   :src="item.user.avatar"
                   alt="" />
              <div class="firm_offer_name"
                   v-if="item.user.firm_offer_name">
                {{ item.user.firm_offer_name }}
              </div>
              <!-- <div
                          class="is_firm_offer"
                          v-if="item.user.is_firm_offer"
                        >
                          <div>实盘</div>
                        </div> -->
              <!-- 用户在线状态 -->
              <div class="online_status1"
                   v-if="item.is_online == 1">
                <div class="staff_status_online"></div>
                在线
              </div>
              <div class="online_status0"
                   v-else>
                <div class="staff_status_online staff_status_offline"></div>
                离线
              </div>
            </div>
            <div class="hang1 tac">{{ item.user.nickname }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
export default {
  data() {
    return {
      circleList: [],
      limit: 1000,
      onlineList: [],
      loading: false,
      total: 0,
      uid: 0
    };
  },
  mounted() {
    this.getCircleList();
  },
  methods: {
    // 获取圈子列表
    async getCircleList() {
      const res = await Service.getCircleList();
      this.circleList = res.data.list;
      console.log("获取圈子列表", res);
      this.getCircleUserList(res.data.list[0].uid);
    },
    // 获取圈子人员列表
    async getCircleUserList(uid) {
      this.loading = true;
      this.uid = uid
      const res = await Service.getCircleUserList({
        uid,
        limit: this.limit,
      });
      this.onlineList = res.data.data;
      this.total = res.data.total
      console.log("获取圈子人员列表", res);
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.active {
  color: #4571e7;
  font-weight: bold;
}

.total_text {
  margin-bottom: 10px;
  font-size: 12px;
  color: #6a6a6a;
}

.user_list {
  max-height: calc(100% - 30px);
  overflow-y: auto;
}

.circle_user_cell {
  font-size: 12px;
  font-weight: 400;
  color: #6a6a6a;
  line-height: 17px;
  margin-right: 16px;
  margin-bottom: 14px;
  width: 48px;
}

.circle_user_cell .avatar {
  width: 46px;
  height: 46px;
  border-radius: 8px;
  display: flex;
}

.firm_offer_name {
  position: absolute;
  bottom: 0;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  width: 100%;
  text-align: center;
  border-radius: 0 0 8px 8px;
}

.online_status1,
.online_status0 {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
}

.online_status1 {
  color: #00ffae !important;
  font-weight: bold;
}
.online_status0 {
}

.staff_status_online,
.staff_status_offline {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 3px 0 5px;
}

.staff_status_online {
  background: #00ffae;
  box-shadow: 0px 1px 4px rgba(11, 182, 120, 0.7);
}

.staff_status_offline {
  filter: grayscale(100%);
  filter: gray;
}

.biankuang {
  border: 1px solid #ff0000;
  border-radius: 8px;
}
</style>